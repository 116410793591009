.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}




h1 { 
  margin: 0%;
  font-family: "Inter";
  font-size: xx-large;
  color: #23422C;
  font-weight: 800;
  padding-right: 10px;
}

h2 { 
  font-family: "Inter";
  font-size: x-large;
  color: #23422C;
  font-weight: 600;
  padding-right: 10px;
}

h3 { 
  font-family: "Inter";
  font-size:medium;
  color: #23422C;
  font-weight: 700;
 
}

p{
  margin: 0%;
  font-family: "Inter";
  font-size:small;
  color: #23422C;
  font-weight: 400
}

.white{
  color: #ffffff;
}
.centered{
  text-align: center;
}

.centered-bold-light{
  text-align: center;
  font-weight: 700;
  color: #75BD64;
}

.centered-bold{
  text-align: center;
  font-weight: 700;
  color: #23422C;
}
.light-green{
  color: #75BD64;
 
}

.bold{
  font-weight: 700;
}

.margin-left{
  margin-left: 40px;
}
.hidden{
  display: none;
}
.error{
  font-weight: 700;
  color: #ab1515;
}


.button-light{
  
  width: 140px;
  height: 50px;
  background: #23422C;

  border: none;
  border-radius: 10px;

  font-family: "Inter";
  font-size:small;
  color: #ffffff;
  font-weight: 600;

  box-shadow: 0px 8px 11px rgb(0, 0, 0, 0.1);

  transition: 0.2s;
}

.button-selector{
  
  width: 140px;
  height: 50px;
  background: rgb(35,66,44);
  margin: 5px;
  margin-left: 0px;

  background: #ffffff00;
  border: solid;
  border-color: #23422C;
  border-radius: 10px;

  font-family: "Inter";
  font-size:small;
  color: #23422C;
  font-weight: 600;

  box-shadow: 0px 8px 11px rgb(0, 0, 0, 0.1);

  transition: 0.2s;
  
}
.button-selector-selected{
  
  width: 140px;
  height: 50px;
  background: rgb(35,66,44);
  margin: 5px;
  margin-left: 0px;

  background: #23422C;
  border: solid;
  border-color: #23422C;
  border-radius: 10px;

  font-family: "Inter";
  font-size:small;
  color: #FFFFFF;
  font-weight: 600;

  box-shadow: 0px 8px 11px rgb(0, 0, 0, 0.1);

  transition: 0.2s;
  
}
.button-selector:hover{
  
  width: 140px;
  height: 50px;
  background: rgb(35,66,44);
  margin: 5px;
  margin-left: 0px;

  background: #23422C;
  border: solid;
  border-color: #23422C;
  border-radius: 10px;

  font-family: "Inter";
  font-size:small;
  color: #FFFFFF;
  font-weight: 600;

  box-shadow: 0px 8px 11px rgb(0, 0, 0, 0.1);

  transition: 0.2s;
  
}
.button-light:hover{
   
    width: 140px;
    height: 50px;
    background: rgb(35,66,44);

    background: #ffffff;
    border: solid;
    border-color: #23422C;
    border-radius: 10px;

    font-family: "Inter";
    font-size:small;
    color: #23422C;
    font-weight: 600;

    box-shadow: 0px 8px 11px rgb(0, 0, 0, 0.1);

    transition: 0.2s;
    
    
}

.radial { 
  width: 25px;
  height: 25px;

  border: #23422C;
  border-style: solid;
  border-radius:100px ;
}

.radial-selected{
  width: 25px;
  height: 25px;
 
  background-color: #23422C;
  border: #23422C;
  border-style: solid;
  border-radius:100px ;

}


.link{
  width: auto;
  height: 40px;

  background-color: #23422c00;
  border-style: none;

  text-align: left;
  margin: 0%;
  font-family: "Inter";
  margin-left: 10px;
  font-size:medium;
  color: #23422C;
  font-weight: 700;
  text-decoration: underline;




}


.separator100{
  height:100px;
}

.separator50{
  height:50px;
}

.separator25{
  height:25px;
}


