.outter-form {
    display: flex;
    flex-direction: column;
    margin: auto;
    justify-self: center;
    justify-content: center;
    max-width: 800px;
    animation: fadein 2s;
  


}

.first-half{
    display:  flex;
    width: auto;
    justify-content: space-between;

}
.second-half{
    display:  flex;

    justify-content: space-between;

}

.form-column{
    display: flex;
    flex-direction: column;
    width: 350px;
}


.input-container {
    display: flex;
    flex-direction: column;
}

.form-input{
    width: 300px;
    height: 30px;
    background-color: #d2d8cf;
    border:none;
    padding: 5px;
    border-radius: 5px;

    font-family: "Inter";
    font-size: large;
    color: #23422C;
    font-weight: 500;
    padding-right: 10px;

}

.form-input-space{
    width: 300px;
    height: 30px;
    background-color: #d2d8cf;
    border:none;
    padding: 5px;
    border-radius: 5px;
    margin-bottom: 10px;

    font-family: "Inter";
    font-size: large;
    color: #23422C;
    font-weight: 500;
    padding-right: 10px;

}


.form-input-small{
    width: 100px;
    height: 30px;
    background-color: #d2d8cf;
    border:none;
    padding: 5px;
    border-radius: 5px;
    margin-bottom: 10px;

    font-family: "Inter";
    font-size: large;
    color: #23422C;
    font-weight: 500;
    padding-right: 10px;

}

.form-input-long{
    width: 300px;
    height: 200px;
    background-color: #d2d8cf;
    border:none;
    padding: 5px;
    border-radius: 5px;
    margin-bottom: 10px;

    font-family: "Inter";
    font-size: large;
    color: #23422C;
    font-weight: 500;
    padding-right: 10px;
    

}


.button-selector-wrapper{
    display: flex;
}

.radial-container{
    display: flex;
    align-items: center ;
}

@media (max-width:750px ){
    .first-half{
        display:  flex;
       
        flex-direction: column;
        min-width: 50vw;
        justify-content: space-between;
    
    }
    .outter-form {

        margin: 25px;
     
      
    
    
    }
    
}