.priv-container{
    display: flex;
    flex-direction: column;
    margin: auto;
    padding-top:100px;
     padding-bottom:100px;
    max-width: 800px;
    justify-content: center;
}

.logo-privacidad{
    width: 150px;
}

.logo-priv-container{
    margin: 50px;
    margin-left: 0px;
}

@media (max-width:750px) {
    .priv-container{
        width: 250px;
  
}
}