.outter-text-container {
    margin: auto;
    justify-content: space-around;
    align-items: center;
    display: flex;
    animation: fadein 2s;
}

@keyframes fadein {
    from { opacity: 0}
    to   { opacity: 1}
  }
  
.text-container {
    display: flex;
    max-width: 500px;
    height: 500px;
    justify-content:center;
    align-items: center;
    background: rgba(255, 255, 255, 0.2);
    backdrop-filter: blur(13px);
}



.tittle-container {
    display: flex;
    max-width: 500px;
    flex-wrap: wrap;
    margin-top: 40px;
    margin-bottom: 50px;
}

.p-container{
    display: flex;
    
    max-width: 400px;
    margin-top: 30px;
    margin-bottom: 60px;

}

.rectangle-container{
    width: 80px;
    height: 20px;
    background-color: #23422C;
    margin-bottom: 20px;
}

.rectangle-1{
    width: 60px;
    height: 20px;
    background-color: #75BD64;
}

.hero-image {
    padding: auto;
    width: 300px;
    height: 400px;
    background-color:rgba(142, 161, 161, 0);
}
.hero-image-home {
    padding: auto;
    width: 100vw;
    height: 500px;

    position: absolute;
    z-index: -1;
    border: solid;
    border-color: #FFFDF6;
    object-position: 10% 70%;
    object-fit: cover;


}







@media (max-width: 750px) {
    .tittle{
        max-width: 300px;
        margin: auto;
    }
    .text-container {
        width: 100vw;
        justify-content: center;
    }
    .outter-text-container {
        display: flex;
        flex-direction: column;
        width: 100vw;
    }
    .hero-image {
        padding: none;
        display: none;

    }

    .hero-image-home {
            height: 300px;
            display: none;

    
    
    }
}