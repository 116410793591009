.outter-steps-container{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    min-height: 500px;
    width: 96vw;
   

}

.card-steps-services {
    max-width: 300px;

    padding-left: 20px;
    padding-right: 20px;
    
}
.card-steps-services-green {
    max-width: 300px;
    background-color: #23422C;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom:20px;
}

@media (max-width:750px) {

    .card-steps-services {
        margin-top:50px;
        margin-bottom: 50px;
    }
    .card-steps-services-green {
        margin-top: 50px;
        margin-bottom: 50px;
    
    }
    
}

