.outter-wrapper-mission-container{
    margin: 20px;
    display: flex;
    max-width: 100vw;    
    justify-content: center;
  
}

.outter-mission-container{
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width:600px;


}

.tittle-container-justified{
    margin-bottom: 100px;


}
.text-mission-container-centered{
    display: flex;

    justify-content: space-between;
    margin: auto;
    width:60vw;
}
.object-mision-container{
    display: flex;
    max-width: 600px;;


    justify-content: space-between;
}
.mission-image{
    margin: auto;
    height: 400px;
    
    
}

@media (max-width: 750px ) {
    .object-mision-container{
        flex-direction: column;
    }
    
    .outter-wrapper-mission-container{
        flex-direction: column;
    }
    .mission-image{
        display: none;

    }
}