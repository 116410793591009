.modal-outter{
    display: flex;
    width: 100vw;
    top: 1px;
    position: sticky;
    height: 100vh;
    margin: auto;
    justify-content: center;
    background: rgba(255, 255, 255, 0.2);
    backdrop-filter: blur(13px);
    z-index: 3;
}


.modal-message{
    display: flex;
    flex-direction: column;
    width: 375px;
    margin: auto;
    justify-content: center;
    align-items: center;
    height: 50vh;
}

.modal-logo{
    margin: auto;
    justify-self: center;
    align-self: center;
}