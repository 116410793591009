.location-outter {
    display: flex;
    
    width: auto;
    margin: auto;
    justify-content: center;
    align-items: center;
}

.location-outter2 {
    display: flex;
 
    width: 90vw;
    margin: auto;
 
    justify-content: space-around;
    align-items: center;
}


.location-img {
    width: 200px;
    justify-self: center;
    margin: auto;
    height: auto;
    margin-bottom: 50px;
   
}

.location-details {
    display: flex;

    justify-content: center;

}

.social-media-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 30px;
  

}

.social-media-button {
    width: 50px;
    height: 50px;
    background-color: rgba(255, 255, 255, 0);
    border: none;
    margin-right: auto;
    margin-left: auto;
}

.location {
    
    width: auto;
    display: flex;
    flex-direction: column;

}

.location2 {
    display: flex;
    flex-direction: column;
    margin:auto;
    justify-content: center;
    align-items: center;

}
.map{
    justify-content: center;
    margin: auto;
    padding: 20px;
    width: auto;
    height:250px;
    border: none;
   border-radius: 20px;
  
}

@media (max-width: 950px) {
    .location-outter{

        flex-direction: column;

    }
    .location-outter2{

        flex-direction: column;
        margin-right: auto;

    }
    

    .location-img {
        margin-top: 100px;

    }

    .map{

        width: 300px;
        margin: auto;

       
      
    }

    .location {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    
    }
}

@media (max-width: 950px) {
 

    .map{

        width: 200px;
        margin: auto;

       
      
    }


}