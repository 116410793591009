.navbar-container {
    height: 80px;
    width: 100%;
  
    justify-content: space-between;
    align-items: center;
    background: rgba(255, 255, 255, 0.387);
    backdrop-filter: blur(13px);
    display: flex;
    z-index: 2;
    top: 0;
    position: sticky;

}

.navbar-logo {
    padding: 50px;
    width: 100px;
    height: auto;
}

.navbar-menu {
    padding: 20px;

}


.navbar-button {

    background-color: rgba(255, 255, 255, 0);
    border: none;

    width: 100px;
    height: 40px;

    font-family: 'Inter';
    font-size: small;
    font-weight: 500;
    color: #23422C;

}

.navbar-button:hover{

    background-color: rgba(255, 255, 255, 0);
    border: none;
 

    font-family: 'Inter';
    font-size: small;
    font-weight: 500;
    color: #05ad35;

}

.navbar-button-pressed {

    background-color: rgba(173, 27, 27, 0);
    border: none;
    width: 100px;
    height: 40px;

    font-family: 'Inter';
    font-size: small;
    font-weight: 700;
    color: #23422C;

}

@media (max-width: 750px) {
    .navbar-menu {
        display: none;
    }
  }