.outter-text-container-centered{

   display: flex;
    width: 100vw;
}

.tittle-container-centered {

    display: flex;
    flex-direction: column;
    margin: auto;
    justify-content: center;
    align-items: center;
    margin-bottom: 50px;


}
.text-container-centered {

    display: flex;
    margin: auto;
    justify-content: space-around;

}
.object-container {
    display: flex;
    width: 70vw;
    justify-content: space-around;
    margin: auto;
    margin-top:25px;
}

.card-container{
    margin: auto;
    margin-top: 50px;
    display: flex;
    flex-direction: column;
    width: 200px ;
    height: 200px;
    justify-content:top;
    align-items: center;
   
}
.card-image{
    width: 100px;
    height: 100px;
    margin-bottom: 25px;
}


@media (max-width:  750px){


    .object-container {
        display: flex;
        flex-direction: column;
        width: 70vw;
        margin-top:0px;
        
        

    }

    .card-image{
        width: 100px;
        height: 100px;
    }

    .card-container{
        margin-top: 50px;
        margin-bottom: 50px;
    
       
    }
}