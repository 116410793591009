.nosotros-image {
    width: auto;
    height: 400px;
   
}

@media (max-width: 950px) {

    .nosotros-image {
    
        width: 100vw;
        height: 250px;
        object-fit:cover;
       
    }
    
}