.calculator-outter-wrapper {
    width: 100vw;
 
    top: 80px;
    bottom:0;
    position:fixed;
    overflow-y:scroll;
    overflow-x:hidden;

    display: flex;
    flex-direction: column;
    
    background: rgba(35, 66, 44, 0.79);
    backdrop-filter: blur(30px);  

    z-index: 2;
 
    justify-content: center;
}

.calculator-wrapper{
    min-width: 300px;
    width: 500px;
    height: 80vh;
    justify-content: center;
    margin: auto;
    margin-top: 0px;
}

.calculator-logo-wrapper{
    display: flex;
    background-color: rgba(35, 66, 44, 1);
    padding: 20px;
    justify-content: center;
    align-items: center;

}

.calculator-logo{
    margin: auto;
    width: 100px;

}
.calculator-flag{
    margin: auto;
    width: 40px;

}
.calculator-arrows{
    margin: auto;
    width: 25px;

}

.calculator-bottom-wrapper{
    padding: 20px;
    background-color:#FFFDF6;
    border-radius: 0px 0px 20px 20px;
    
}

.calculator-selector-wrapper{
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 250px;
    margin: auto;
}

.calculator-selector-buttons-wrapper{
    display: flex;
   
}

.calculator-input-wrappers{
    display: flex;
    flex-direction: row;
    justify-content: center;

}
.calculator-column{
    display: flex;
    flex-direction: column;
    margin: 20px;
}

.calculator-form-input{
    height: 50px;
    width: 125px;
    border: none;
    background-color: #d2d8cf;
    border-radius: 10px;

    font-family: 'Inter';
    font-weight: 600;
    font-size: medium;
    text-align: center;
}

.calculator-button-container{
    margin-top: 55px;
    
}


.calculator-result-wrapper{
    justify-content: space-between;
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 100px;

}

.calculator-button{
    width:  60px;
    height: 60px;
    background-color: #23422C;
    border: none;
    border-radius: 100px;
    position: fixed;
    right: 0px;
    margin: 50px;
    box-shadow: 0px 9px 16px rgba(0, 0, 0, 0.25);
    z-index: 3;
}

.calculator-button:hover{
   
    background-color: #FFFDF6;
    border: solid;
    border-color: #23422C;
}

.Calculator-image{
    width: 25px;
}


@media (max-width:500px) {



    .calculator-bottom-wrapper{
        width: 100vw;
        height: 100vh;
        padding-left: 0px;
    
        border-radius: 0px ;

    }


    .calculator-logo-wrapper{
        display: flex;
        width: 100vw;
        background-color: rgba(35, 66, 44, 1);
        padding:0px;
        padding-top:20px;
        padding-bottom:20px;
        justify-content: center;
        align-items: center;
    
    }

   


    .calculator-button{
        bottom: 0px;
        margin: 20px;
        width: 50px;
        height: 50px;
    }

    .Calculator-image{
        width: 20px;
    }
}