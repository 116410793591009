.hero-image-services {
    padding: auto;

    height: 400px;
   right: 100px;
    position: absolute;
    z-index: -1;


}

@media (max-width: 750px) {
    

    .hero-image-services {
       
        width:90vw;
        height:auto;
        right: auto;
        position: relative;
        z-index: -1;
        opacity: 30%;
     



    
    
    }
}