.p-container-contactanos {
    display: flex;
    max-width: 400px;
    margin:auto;
    margin-top: 30px;
    margin-bottom: 60px;
    justify-content: center;
}
.outter-contactanos-container{
   display: flex;
   justify-content: center;
   margin: auto;
   align-items: center;
}
.contactanos-image {
  width:   auto;
   height: 100px;

}

@media (max-width:750px) {
    .outter-contactanos-container{
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin: auto;
        align-items: center;
     }
    .p-container-contactanos {
        display: flex;
        flex-direction: column;
        max-width: 400px;
        margin-top: 30px;
        margin-bottom: 60px;
    }

    .contactanos-image {
        width: 300px;
        margin-bottom: 150px;
    
    }
}