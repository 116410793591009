.footer-container{
    display: flex;
    justify-content: space-around;
    background-color: #23422C;
    align-items: center;
}

.footer-buttons-container {
        display: flex;
        padding: 20px;
    
}

.footer-button{
    width: 50px;
    background-color: #23422c00;
    border: none;
}

.footer-logo {
    padding: 50px;
    width: 100px;
    height: auto;
}


@media (max-width:750px) {
    .footer-container{
        flex-direction: column;
        height: 200px;
        padding: 10px
        
    }
    .footer-buttons-container {
        display: flex;

}



    .footer-logo {
        padding:0px;
        width: 100px;
        height: auto;
    }
    
}