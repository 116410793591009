.uConstruction {
    height: 80vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

}

.uConstruction-logo{
   
    padding: 50px;
    padding-top: 0px;
    width: 200px;
}